import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import ProductFiltersContext from '@/context/ProductFiltersContext';

import Grid from '@/atoms/Grid';

import CategoryHeader from '@/components/CategoryHeader';
import ProductGrid from '@/components/ProductGrid';
import SchemaOrg from '@/components/SchemaOrg';
import Head from '@/components/Head';

export default ({ data, pageContext, location }) => {
  const { wines, regions } = data.graph;
  const [productFilters] = useContext(ProductFiltersContext);

  const sections = regions.filter(region => region.parentRegion === null);

  const sectionFilter = (section, wine) =>
    wine.producer.regions.map(region => region.id).includes(section.id);

  return (
    <>
      <Head title={pageContext.pageTitle} canonical={location.pathname} />
      <SchemaOrg
        type="breadcrumbs"
        navigationTags={pageContext.defaultNavigationTags}
      />

      <Grid.Container as="section">
        <CategoryHeader title={pageContext.pageTitle} />

        <ProductGrid
          sections={sections}
          sectionFilter={sectionFilter}
          filters={productFilters}
          products={wines}
          tileSize="small"
          hideSoldOut
        />
      </Grid.Container>
    </>
  );
};

export const query = graphql`
  query($id: ID!) {
    graph {
      regions(
        where: {
          country: { id: $id }
          parentRegion: null
          producers_some: { wines_none: { id: null } }
        }
        orderBy: name_ASC
      ) {
        id
        name
        parentRegion {
          id
        }
        subRegions(
          where: { producers_some: { wines_none: { id: null } } }
          orderBy: name_ASC
        ) {
          id
          name
        }
      }
      wines(
        where: {
          status: PUBLISHED
          producer: { regions_some: { country: { id: $id } } }
        }
        orderBy: sku_ASC
      ) {
        ...WineData
      }
    }
  }
`;
